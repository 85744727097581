import React, { Component } from "react";
import { Link } from "react-router-dom";


class Abc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  render() {
    // 
    return (
    <>
  <footer>
         <div class="footer-section">
            <div class="container">
               <div class="row">
                  <div class="col-lg-3 col-md-3">
                     <div class="footer-logo">
                        <a href="#"> <img style={{
                              color: "black",
                              backgroundColor: "currentcolor",
                              borderRadius:" 50%"
                          
                        }} src="img/logo1.png" alt=""/></a>


                     </div>
                  </div>
                  <div class="col-lg-4 col-md-5">
                     <div class="footer-content">
                        <h3>Connect</h3>
                        <p>For any questions, assistance, or feedback</p>
                        <p>Email: <b>ask@VKConstruct.com</b> </p>
                     </div>
                  </div>
                  <div class="col-lg-5 col-md-4">
                     <div class="footer-content">
                        <h3>Other Links</h3>
                        <ul>
                           {/* <li><a href="#">What we do</a></li> */}
                           <li><Link to="/" >What we do</Link></li>
{/*                            
                           <li><Link to="/blog" >BLOG</Link></li>
                           <li><Link to="/connect" >Connect</Link></li>
                           <li><Link to="/joinUs" >Join Us</Link></li> */}
                           <li><Link to="/connect" >Contact</Link></li>
                        </ul> 

                           {/* <li><a href="http://phpstack-473415-1486292.cloudwaysapps.com/ramandeep/Infinite/join-us.html">Join Us</a></li>
                           <li><a href="http://phpstack-473415-1486292.cloudwaysapps.com/ramandeep/Infinite/blog.html">Blog</a></li>
                           <li><a href="http://phpstack-473415-1486292.cloudwaysapps.com/ramandeep/Infinite/contact.html">Connect</a></li>
                        </ul> */}
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="copy-right">
            <p><img src="img/copy.png" alt=""/> VKConstruct 2022. All Rights Reserved</p>
         </div>
      </footer>
    </>
    );
  }
}

export default Abc;
