import React, { useState, useEffect } from "react";
import blogActions from "../../actions/homeActions";
import Navbar from "../NavBar";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Header =(props)=> {
   const [homeData,setHomeData]=useState({});
   const [isHome,setIsHome]=useState(false);
   const [ShowTopBtn,setShowTopBtn]=useState(false)

const history=useNavigate(); 
let location = useLocation();
let pathname=location.pathname;
useEffect(()=>{
 
   let type=location.pathname;
   type= type.substring(1)
   if(!type.length>0){
      type="home"
      setIsHome(true)
   }
   let payload={
      type
   };
   console.log(type)
   blogActions.getHomeData(payload,(err,res)=>{
      if(err){

      }else{

         setHomeData(res.data);
         console.log(res.data)
      }
   })
},[])
useEffect(() => {
   window.addEventListener('scroll', () => {
      
       if (window.scrollY > 400) {
           setShowTopBtn(true);
       } else {
           setShowTopBtn(false);
       }
   });
}, []);
const scrollToTop = () =>{
   window.scrollTo({
     top: 0, 
     behavior: 'smooth'
   });
 };
    return (
       <>

{ShowTopBtn==true?<div className="topbotton"><button
      type="button"
      class="btn btn-floating btn-lg" 
      onClick={(e)=>{
         e.preventDefault();
         scrollToTop();

      }}
      >
      <img src="img/up-down.png" alt=""/>
      </button></div>:""}
 
      <section class="main-banner-wrap" >
         {/* <div class="banner-bg-video" >
         {homeData.videoUrl?
         <video autoPlay muted loop id="myVideo" preload="auto">
               <source src={homeData.videoUrl} type="video/mp4"/>
               Your browser does not support HTML5 video.
            </video>:""}
         </div> */}
          <div class="banner-bg-video" >
            <video autoPlay muted loop id="myVideo" preload="auto">
               <source src="./img/bg-video210.mp4" type="video/mp4" />
               Your browser does not support HTML5 video.
            </video>
         </div>
         <div class="overlay-above-content" onscroll="myFunction()">
            <header>
             
               <Navbar></Navbar>
               <div class="container">
                  <div class="banner-title">
                     
                 
               {/* {isHome==true?
               
               <h1>{homeData.text}</h1> : */}
               <div class="home-page">
                     <p><span>Home</span> &gt; {location.pathname.substring(1)}</p>
                     <h4>Revolutionizing Construction: Unleashing the Power of BIM Solutions
Unlock the potential of your projects</h4>
                     <p>Dive into the world of streamlined workflows, enhanced collaboration, and project efficiency, as we guide you through the transformative impact of BIM solutions.</p>
                  </div>
               {/* } */}
                </div>
               </div>
               
            
            
            </header>
         </div>
         </section>
       </>
    );
  
               }

export default Header;
