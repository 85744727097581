import React, { Component, useState, useEffect } from "react"
import Header from "../TopHeader"
import Abc from "../footer"
import { Link, useLocation, useNavigate } from "react-router-dom"

import blogActions from "../../actions/homeActions"
import moment from "moment"
import ContactC from "../ContactC"
import { Portfolio } from "../portfo"
const EditFormPost = (props) => {
  const history = useNavigate()

  const [Expert, setExpert] = useState([])
  const [blogList, setBlog] = useState([])
  const [ourWorkList, setOurWorkList] = useState([])

  useEffect(() => {
    let payload = {}
    blogActions.getExpert((err, res) => {
      if (err) {
      } else {
        setExpert(res.data)
      }
    })
  }, [])
  useEffect(() => {
    let payload = {}
    blogActions.getBlog(payload, (err, res) => {
      if (err) {
      } else {
        setBlog(res.data.blogList)
      }
    })
    blogActions.getOurWork((err, res) => {
      if (err) {
      } else {
        setOurWorkList(res.data)
      }
    })
  }, [])
  return (
    <>
      <Header></Header>

      <section class="space-wapper">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-head">
                <span>about us</span>
                <h2>
                  {" "}
                  Who we <span class="diff">are</span>
                </h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="p-content">
                <p>
                  We specialize in delivering cutting-edge BIM services tailored
                  to meet the unique needs of your construction projects. Our
                  expert team combines innovative technology with industry
                  knowledge to enhance collaboration, streamline workflows, and
                  optimize project efficiency. From 3D modeling and clash
                  detection to virtual construction and project coordination, we
                  offer comprehensive BIM solutions that elevate the success of
                  your ventures.
                </p>
                <br />
                <p>
                  {" "}
                  Partner with us for a smarter, more integrated approach to
                  construction and experience the power of next-generation BIM
                  solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="space-wapper down-space">
        <div class="container">
          <div class="row" style={{
            justifyContent: "center"
          }}>
            <div class="col-lg-6 col-md-6">
              <div class="aside-head">
                <img src="img/bim-and-augmented-reality-bridging-the-gap-between-design-and-construction_4.jpg" alt="" />
                <h3>Our Vision: Making the future together</h3>
                <p>
                  Our Vision is to be leaders in shaping the future of digital
                  construction using teamwork and the latest technology. Imagine
                  a world where every digital structure is a sign of using smart
                  ideas, moving forward with technology, and looking great
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="aside-head">
                <img src="img/1688194292059.png" alt="" />
                <h3>Our Mission: Making Ideas Real</h3>
                <p>
                  Our mission is to turn your ideas into real digital
                  structures. With the help of BIM, we work hard to make your
                  ideas into things you can see. We promise to be clear, work
                  together, and make something great every time.
                </p>
              </div>
            </div>
            {/* <div class="col-lg-4 col-md-6">
              <div class="aside-head">
                <img src="img/icon23.png" alt="" />
                <h3>The Values We Hold Dear</h3>
                <p>
                  Believe in the good Be inclusive Think collaboration Think
                  impact
                </p>
              </div>
            </div> */}

            <div class="col-lg-6 col-md-6">
              <div class="aside-head min-widh">
                <img src="img/bim-solutions-hero.jpg" alt="" />
                <h3>Our Mantra</h3>
                <p>Building Dreams, BUild on Trust</p>
              </div>
            </div>
            {/* <div class="col-lg-6 col-md-6">
              <div class="aside-head min-widh">
                <img src="img/icon25.png" alt="" />
                <h3>Our Purpose</h3>
                <p>
                  Create collaborative communities that bring positive change to
                  the world.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      {/* <section class="space-wapper bg-back">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="section-head wid-long">
                     <span>Experts</span>
                     <h2>Your <span class="diff">friends</span> behind IC </h2>
                  </div>
               </div>
            </div>
            <div class="row">
               {Expert.length>0?Expert.map((ele)=>{
                  return(
                     <div class="col-lg-3 col-md-3">
                  <div class="profile-client">
                     <div class="box-client">
                        <img src={ele.imageUrl} alt=""/>
                        <div class="overlay" onClick={(e=>{
                           e.preventDefault();
                           window.location.href = ele.profileUrl;
                        })}>
                           <div class="text">View Profile </div>
                        </div>
                     </div>
                  </div>
                  <div class="info-client">
                     <h4>{ele.name}</h4>
                     <a href={ele.profileUrl}>{ele.position}</a>
                  </div>
               </div>
                  )
               }):""}
               
               
      
               {/* <div class="col-lg-3 col-md-3 down">
                  <div class="profile-client">
                     <div class="box-client">
                        <img src="img/4.png" alt=""/>
                        <div class="overlay">
                           <div class="text">View Profile </div>
                        </div>
                     </div>
                  </div>
                  <div class="info-client">
                     <h4>Monica Smith</h4>
                     <a href="#">Designer</a>
                  </div>
               </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </section> */}
      <section class="space-wapper">
        <div class="container-fulid">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-head what-long">
                <span>what we do</span>
                <h2>
                  our <span class="diff">services</span>
                </h2>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div class="row">
            <div class="col-lg-12">
              <div class="p-content">
                <div class="banner-bg-video sceond-video">
                  <video autoPlay muted loop id="myVideo" preload="auto">
                    <source src="/img/sceond.mp4" type="video/mp4" />
                    Your browser does not support HTML5 video.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="p-content">
                <div class="by-title">
                  <h5>Empowering Construction Excellence, Together</h5>
                  <p>
                    Through collaborative efforts from our community of BIM
                    specialists, architects, engineers, project managers, and
                    other champions of construction innovation, we unite to
                    deliver solutions that advance the efficiency and impact of
                    BIM for our clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="space-wapper">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-4">
              <div class="list-item">
                <h2>Our Services</h2>
                <ul>
                  <li>
                    {" "}
                    <a href="#">MEPF BIM</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">Architectural BIM</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">Structural BIM</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">Scan to BIM</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">BIM Coordination</a>{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="list-item">
                <h2>Strategy</h2>
                <ul>
                  <li>
                    {" "}
                    <a href="#">Vision And Mission</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">Services Portfolio </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">technological Infrastructure</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#"> Thought Leadership </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#"> Talents Development </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">clients Engagement</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#"> Sustainability integration</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">Collaboration And integration</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">Quality Assurance</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">Continuos Improvement</a>{" "}
                  </li> 
                </ul>
              </div>
            </div>
            {/* <div class="col-lg-4 col-md-4">
              <div class="list-item">
                <h2>Strategy</h2>
                <ul>
                  <li>
                    {" "}
                    <a href="#">Accessibility & Safety </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#"> Training & Certifications </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#"> Workshops & Seminars </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#"> Sustainability Reporting </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#"> Environmental Services </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#"> Women’s Health & Empowerment </a>{" "}
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section class="space-wapper">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-head work">
                <span>Portfolio</span>
                <h2>
                  Our <span class="diff">Work</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="top-wapper">
         
            <Portfolio/>
          
        </div>
      </section>
      {/* <section class="space-wapper">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-head us">
                <span>Our Team</span>
                <h2>
                  Join<span class="diff">Us</span>
                </h2>
              </div>
              <div class="p-content">
                <div class="by-title">
                  <h5>want to share your advocacy with the world?</h5>
                  <p>
                    Join our growing community of strategists, creative minds,
                    content creators, social and environmental program
                    designers, and other advocates of social good – let us work
                    together on sustainable solutions that make the world a
                    better place.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="Joining-text">
                <h4>Joining as an organization</h4>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="list-item spacelist">
                <h2>Non-Profits</h2>
                <span>(Annual Membership Fee: $100)</span>
                <ul>
                  <li>
                    {" "}
                    <a href="#">Access to the IC global network and content</a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">
                      Exclusive access to IC Work Hub and resources
                    </a>
                  </li>
                </ul>
              </div>
              <div class="list-item spacelist">
                <h2>For-Profit Organizations</h2>
                <ul>
                  <li>
                    {" "}
                    <a href="#">SMEs</a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">Corporate ($1.5M+ annual turnover)</a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">Start-Ups/Incubators</a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">Schools & Other Educational Institutions</a>
                  </li>
                </ul>
              </div>
              <div class="list-item spacelist">
                <h2>Schools & Other Educational Institutions</h2>
                <span>Institutions (Annual Membership Fee: $1000)</span>
                <ul>
                  <li>
                    {" "}
                    <a href="#">Access to the IC global network and content</a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">Business Discovery Session</a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">Impact Strategy Action Plan</a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">
                      Social advocacy session for students (complimentary
                      session)
                    </a>
                  </li>
                </ul>
              </div>
              <div class="list-item spacelist">
                <h2>Joining as an individual</h2>
                <span>(Annual Membership Fee: $100)</span>
                <ul>
                  <li>
                    {" "}
                    <a href="#">Access to the IC global network and content</a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">
                      Exclusive access to IC Work Hub and resources
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">Purpose Discovery (Group) Session</a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">
                      Preferential rate on one-on-one advocacy coaching
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="list-item spacelist">
                <h2>SMEs/Start-Ups </h2>
                <span>(Annual Membership Fee: $1000) (or $100 per month)</span>
                <ul>
                  <li>
                    {" "}
                    <a href="#">
                      Exclusive access to IC Work Hub and resources
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">Business Discovery Session</a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">Impact Strategy Action Plan </a>{" "}
                  </li>
                </ul>
              </div>
              <div class="list-item spacelist">
                <h2>Corporate</h2>
                <span>(Annual Membership Fee: $1000) (or $100 per month)</span>
                <ul>
                  <li>
                    {" "}
                    <a href="#">SMEs</a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">Corporate ($1.5M+ annual turnover)</a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">Corporate</a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">Impact Strategy Action Plan</a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">
                      Priority access to social impact workshops and conferences
                    </a>
                  </li>
                </ul>
              </div>
              <div class="from-section">
                <form>
                  <div class="form-group">
                    <label for="">Name*</label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder=""
                    />
                  </div>
                  <div class="form-group">
                    <label for="">Address</label>
                    <input type="" class="form-control" id="" placeholder="" />
                  </div>
                  <div class="form-group">
                    <label for="">Passion/Expertise</label>
                    <select class="" id="">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="">Product/Services</label>
                    <select class="" id="">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <a href="#" class="blue-text-right">
                      Mail Us
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section class="space-wapper">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-head latest-Articles what-long">
                <span>News and blogs</span>
                <h2>
                  Read all our <span class="diff">latest Articles</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div class="top-wapper">
          <div class="container">
            <div class="row">
               {blogList.length>0?blogList.map((ele)=>{
               let ArticleBody= ele.ArticleBody.substring(0, 100)
               return(
                  <>
                  
                  <div class="col-lg-4 col-md-4">
                     <div class="our-content">
                        <img src={ele.imageUrl} alt="" //>
                        <h3>{ele.ArticleTitle}</h3>
                        <h5>{moment(ele.createAt).format("YYYY-MM-DD")} / <span>by {ele.publishedBy}</span></h5>
                        <p>{ArticleBody}</p>
                        <a href="#">Read More <img src="img/Arrow-next.png" alt="" //></a>
                     </div>
                  </div>
                 
                  </>
               )
            }):""}
               </div>
            <div class="row">
              <div class="col-lg-4 col-md-4">
                <div class="our-content">
                  <img src="img/read1.png" alt="" />
                  <h3>Tales that time forget</h3>
                  <h5>
                    12-03-2020 / <span>by Romanian Cristian</span>
                  </h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetr adipiscing elit.
                    Adipiscing
                  </p>
                  <a href="#">
                    Read More <img src="img/Arrow-next.png" alt="" />
                  </a>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="our-content">
                  <img src="img/read2.png" alt="" />
                  <h3>Water, water everywhere and not a drop to drink</h3>
                  <h5>
                    12-03-2020 / <span>by Kevin Johnson</span>
                  </h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetr adipiscing elit.
                    Adipiscing
                  </p>
                  <a href="#">
                    Read More <img src="img/Arrow-next.png" alt="" />
                  </a>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="our-content">
                  <img src="img/read3.png" alt="" />
                  <h3>Who’s dictating the narrative on sustainability?</h3>
                  <h5>
                    12-03-2020 / <span>by Kevin Johnson</span>
                  </h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetr adipiscing elit.
                    Adipiscing
                  </p>
                  <a href="#">
                    Read More <img src="img/Arrow-next.png" alt="" />
                  </a>
                </div>
              </div>
            </div>

            <br />
            <br />
            <div class="row">
              <div class="col-12">
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    history("./blog")
                  }}
                  class="blue-text"
                >
                  View More
                </button>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section class="space-wapper pb-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="say-content">
                <span>Contact us</span>
                <h2>
                  Say <span class="diff">Hello</span>
                </h2>
                <br />
                <br />
                <br />
                <p>For any questions, assistance, or feedback</p>
                <p>
                  Email: <b>ask@VKConstruct.com</b>{" "}
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="info-section">
                <form>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label for="">First Name*</label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="">Last Name*</label>
                        <input
                          type=""
                          class="form-control"
                          id=""
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="">Email ID*</label>
                      <input
                        type=""
                        class="form-control"
                        id=""
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="">Message*</label>
                      <textarea
                        class="form-control"
                        name=""
                        id=""
                        cols="30"
                        placeholder=""
                        rows="10"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-12">
                    <a href="#" class="blue-text-right">
                      Mail Us
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <ContactC />
      <Abc></Abc>
    </>
  )
}

export default EditFormPost

{
  /* const [selectedOption, setSelectedOption] = useState(options[0].value);
  return (
      <select
        value={selectedOption}
        onChange={e => setSelectedOption(e.target.value)}>
        {options.map(o => (
          <option key={o.value} value={o.value}>{o.label}</option>
        ))}
      </select> */
}


{/* <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-4">
                <div class="aside-content">
                  <img src="img/output_image1.png" alt="" />
                  <h3>Building Mechanical Services</h3>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="aside-content">
                  <img src="img/output_image4.png" alt="" />
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="aside-content">
                  <img src="img/output_image.png" alt="" />
                  <h3>Kitchen exhaust system</h3>
                </div>
              </div>
            
            </div>
            <div class="row">
              
  
               <div class="col-lg-4 col-md-4">
                <div class="aside-content">
                  <img src="img/hello.png" alt="" />
                  <h3>Calbe tray model of a Building</h3>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="aside-content">
                  <img src="img/WhatsApp123.png" alt="" />
                  <h3>Calbe tray model of a Building</h3>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="aside-content">
                  <img src="img/WhatsApppiuy.png" alt="" />
                  <h3>3D Model of cable tray connect </h3>
                </div>
              </div>
        
            </div>
            <br />
            <br />
            <div class="row">
              <div class="col-12">
                <a href="#" class="blue-text">
                  View More
                </a>
              </div>
            </div>
          </div> */}  