import React, { Component, useState, useEffect } from "react"
import Header from "../TopHeader"
import Abc from "../footer"
const FormPost = (props) => {

  return (
   <>

       <Header></Header>
     

      <section class="space-wapper">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="section-head us">
                     <span>Our Team</span>
                     <h2>Join<span class="diff">Us</span></h2>
                  </div>
                  <div class="p-content">
                     <div class="by-title">
                        <h5>want to share your advocacy with the world?</h5>
                        <p>Join our growing community of strategists, creative minds, content creators, social and environmental program designers, and other advocates of social good – let us work together on sustainable solutions that make the world a better place.</p>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-12">
                  <div class="Joining-text">
                     
                     <h4>Joining as an organization</h4>
                  </div>
               </div>
            </div>
            <br/>
            <br/>
            <div class="row">
               <div class="col-lg-6 col-md-6">
                  <div class="list-item spacelist">
                     <h2>Non-Profits</h2>
                     <span>(Annual Membership Fee: $100)</span>
                     <ul>
                        <li> <a href="#">Access to the IC global network and content
                           </a> 
                        </li>
                        <li> <a href="#">Exclusive access to IC Work Hub and resources
                           </a> 
                        </li>
                     </ul> 
                  </div>
                  <div class="list-item spacelist">
                     <h2>For-Profit Organizations</h2>
                     <ul>
                        <li> <a href="#">SMEs 
                           </a> 
                        </li>
                        <li> <a href="#">Corporate ($1.5M+ annual turnover)
                           </a> 
                        </li>
                        <li> <a href="#">Start-Ups/Incubators
                           </a> 
                        </li>
                        <li> <a href="#">Schools & Other Educational Institutions
                           </a> 
                        </li>
                     </ul>
                  </div>
                  <div class="list-item spacelist">
                     <h2>Schools & Other Educational Institutions</h2>
                     <span>Institutions (Annual Membership Fee: $1000)</span>
                     <ul>
                        <li> <a href="#">Access to the IC global network and content
                           </a> 
                        </li>
                        <li> <a href="#">Business Discovery Session
                           </a> 
                        </li>
                        <li> <a href="#">Impact Strategy Action Plan
                           </a> 
                        </li>
                        <li> <a href="#">Social advocacy session for students (complimentary session)
                           </a> 
                        </li>
                     </ul>
                  </div>
                  <div class="list-item spacelist">
                     <h2>Joining as an individual</h2>
                     <span>(Annual Membership Fee: $100)</span>
                     <ul>
                        <li> <a href="#">Access to the IC global network and content
                           </a> 
                        </li>
                        <li> <a href="#">Exclusive access to IC Work Hub and resources
                           </a> 
                        </li>
                        <li> <a href="#">Purpose Discovery (Group) Session
                           </a> 
                        </li>
                        <li> <a href="#">Preferential rate on one-on-one advocacy coaching
                           </a> 
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="col-lg-6 col-md-6">
                  <div class="list-item spacelist">
                     <h2>SMEs/Start-Ups </h2>
                     <span>(Annual Membership Fee: $1000) (or $100 per month)</span>
                     <ul>
                        <li> <a href="#">Exclusive access to IC Work Hub and resources
                           </a> 
                        </li>
                        <li> <a href="#">Business Discovery Session
                           </a> 
                        </li>
                        <li> <a href="#">Impact Strategy Action Plan </a> </li>
                     </ul>
                  </div>
                  <div class="list-item spacelist">
                     <h2>Corporate</h2>
                     <span>(Annual Membership Fee: $1000) (or $100 per month)</span>
                     <ul>
                        <li> <a href="#">SMEs 
                           </a> 
                        </li>
                        <li> <a href="#">Corporate ($1.5M+ annual turnover)
                           </a> 
                        </li>
                        <li> <a href="#">Corporate
                           </a> 
                        </li>
                        <li> <a href="#">Impact Strategy Action Plan
                           </a> 
                        </li>
                        <li> <a href="#">Priority access to social impact workshops and conferences
                           </a> 
                        </li>
                     </ul>
                  </div>
                  <div class="from-section">
                     <form>
                        <div class="form-group">
                           <label for="">Name*</label>
                           <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=""/>
                        </div>
                        <div class="form-group">
                           <label for="">Address</label>
                           <input type="" class="form-control" id="" placeholder=""/>
                        </div>
                        <div class="form-group">
                           <label for="">Passion/Expertise</label>
                           <select class="" id="">
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                           </select>
                        </div>
                        <div class="form-group">
                           <label for="">Product/Services</label>
                           <select class="" id="">
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                           </select>
                        </div>
                        <div class="form-group">
                           <a href="#" class="blue-text-right">Mail Us</a>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </section>
  
      <Abc></Abc>
   </>
  )
}

export default FormPost

