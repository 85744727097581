import React, { Component, useEffect, useState } from "react"
import Header from "../TopHeader"
import Abc from "../footer"
import homeActions from "../../actions/homeActions"
import emailjs from 'emailjs-com';
import ContactC from "../ContactC";

const Contact = (props) => {
  const [name, setName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const sendmail = () => {

    console.log(name, lastName, email, message, "data to name");
    if(!name&&!email&&!message){
       alert("fill information");
       return;
    }
    let data={
       name,lastName,message,email
    }
    emailjs.send("service_q400lsp","template_iq5mmpl",{
      from_name: name,
      to_name: "Abhinav",
      message: message,
      reply_to: email ,
      }).then((result) => {
        console.log(result.text);
        alert("mail sent")
      }, (error) => {
        console.log(error.text);
      });
    // emailjs.sendForm('service_q400lsp', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID')
    // .then((result) => {
    //     window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
    // }, (error) => {
    //     console.log(error.text);
    // });

  }

  return (
    <>
      <Header></Header>

      <ContactC />

      <Abc></Abc>
    </>
  )
}

export default Contact
