import React, { useState, useEffect } from "react";
import blogActions from "../../actions/homeActions";
import { Link, useLocation, useParams,useNavigate } from "react-router-dom";
import { type } from "@testing-library/user-event/dist/type";

const Header =(props)=> {
   const [homeData,setHomeData]=useState({});
   const [isHome,setIsHome]=useState(false);

const history=useNavigate(); 
let location = useLocation();
let pathname=location.pathname;
pathname= location.pathname.substring(1)
useEffect(()=>{

   let type=location.pathname;
   type= type.substring(1)
   if(!type.length>0){
       console.log(type, "asdfghjkl;sdfghjkl;")
      type="home"
      setIsHome(true)
    }

   let payload={
      type
   };
   console.log(type)
   blogActions.getHomeData(payload,(err,res)=>{
      if(err){

      }else{

         setHomeData(res.data);
         console.log(res.data)
      }
   })
},[])
    return (
       <>
         
               <nav class="navbar py-0">
                     <div class="relate-app">
                        <div class="light-nav position-fixed">
                           <nav class="navbar navbar-expand-md navbar-light w-100 py-0">
                              <a href="/" class="navbar-brand logo-part">
                                 <img src="/img/logo1.png" alt=""/>
                                 </a>
                              <button type="button" class="navbar-toggler logo-part-botton" data-bs-toggle="collapse"
                                 data-bs-target="#navbarCollapse">
                              <img src="/img/menu-web.png" alt=""/>
                              </button>
                              <div class="collapse navbar-collapse center-part" id="navbarCollapse">
                              <div class="navbar-nav nav-links-menu light-nav-2">
                                    <Link to="/" className={`${location.pathname === "/" ? "active" : "" }`}>Home</Link>
                                 </div>
                                 <div class="navbar-nav nav-links-menu light-nav-2">
                                    <Link to="/connect" className={`${location.pathname === "/connect" ? "active" : "" }`}>Connect</Link>
                                 </div>
                                 <div class="navbar-nav nav-links-menu light-nav-2">
                                    <Link to="/Services" className={`${location.pathname === "/Services" ? "active" : "" }`}>Services</Link>
                                 </div>
                                 <div class="navbar-nav nav-links-menu light-nav-2">
                                    <Link to="/Portfolio" className={`${location.pathname === "/Portfolio" ? "active" : "" }`}>Portfolio</Link>
                                 </div>
                              </div>
                              <div class="hide-show">
                                 <img src="/img/menu-web.png" alt=""/>
                              </div>
                           </nav>
                        </div>
                     </div>
               </nav>
            
              
       
        
       </>
    );
  
               }

export default Header;
