import Agent from "./superAgent";
import config from '../config/configg';
import {ServerError} from '../utils/helpers';
const BACKEND_URL = config.BACKEND_URL;

function getBlog(payload, cb) {
  Agent
    .fire('post', `${BACKEND_URL}/websites/getBlog`)
    .send(payload)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}
function getExpert(cb) {
  Agent
    .fire('get', `${BACKEND_URL}/websites/getExpert`)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}
function getOurWork(cb) {
  Agent
    .fire('get', `${BACKEND_URL}/websites/getOurWork`)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}
function getHomeData(type, cb) {
  Agent
    .fire('get', `${BACKEND_URL}/websites/getHomeScreenContent`)
    .query(type)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}
function sendMailToAdmin(payload, cb) {
  Agent
    .fire('post', `${BACKEND_URL}/websites/sendMailToAdmin`)
    .send(payload)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}
function getParticularBlog(payload,cb) {
  Agent
    .fire('get', `${BACKEND_URL}/websites/getParticularBlog`)
    .query(payload)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });

  }

function addPost(payload, cb) {
  Agent
    .fire('post', `${BACKEND_URL}/users/postJob`)
    .send(payload)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}

function deletePost(id, cb) {
  Agent
    .fire('get', `${BACKEND_URL}/users/deletePost/${id}`)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}

function repost(id, cb) {
  Agent
    .fire('get', `${BACKEND_URL}/users/repost/${id}`)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}



function Search(payload, cb) {
  Agent
    .fire('get', `${BACKEND_URL}/users/searchJobs?text=${payload}`)
    .end((err, res) => {
      var error = err || res.error ? ServerError(res) : (res.body && res.body.error) ? ServerError(res) : null;
      if (typeof cb === 'function') return cb(error, res && res.body);
    });
}


export default {
  getBlog,
  getExpert,
  getOurWork,
  getHomeData,
  sendMailToAdmin,
  getParticularBlog
}