import React, { Component, useEffect, useState } from "react"
let projects = [
    {
      id: 1,
      title: "Calbe tray model of a Building",
      startDate: "2021-06-01",
      endDate: "2021-06-01",
      description: "project1",
      image: "img/hello.png",
      link: ""
    },
    // <img src="img/output_image.png" alt="" />
    // <h3>Kitchen exhaust system</h3>
    {
        id: 2,
        title: "Kitchen exhaust system",
        startDate: "2021-06-01",
        endDate: "2021-06-01",
        description: "project2",
        image: "img/output_image.png",
        link: ""
    },
    {
        id: 3,
        title: "Building Mechanical Services",
        startDate: "2023-06-01",
        endDate: "2021-06-01",
        description: "project3",
        image: "img/output_image1.png",
        link: ""
        },
        {
            id:4,
            title:"3D Model of cable tray connect",
            startDate: "2023-12-07",
            endDate: "2021-06-01",
            description: "project3",
            image: "img/WhatsApppiuy.png",
            link: ""

        },
        // enhanced-image(2).png
        // IMG-20240216-WA0008.jpg
        {
            id:5,
            title:"Bathroom Group Plumbing Connection",
            startDate: "2024-2-01",
            description: "project3",
            image: "img/enhanced-image(2).png",
            link: ""

        },    
        {
            id:6,
            title:"Unit Room Bathroom Plumbing Group",
            startDate: "2024-2-01",
            description: "project3",
            image: "img/IMG-20240216-WA0008.jpg",
            link: ""

        },
]
export const Portfolio = (props) => {
    return (
    <>
    <section id="portfolio">
        <div className="col-md-12">
          <div className="col-md-12 mx-auto">
            <div className="row mx-auto">
                {projects.map((project) => {
                    return (<>
                       <div
            className="col-sm-12 col-md-6 col-lg-4"
            key={project.id}
            style={{ cursor: "pointer" }}
          >
            <span className="portfolio-item d-block">
              <div className="foto">
                <div>
                  <img
                    src={project.image}
                    alt="projectImages"
                    height="230"
                    style={{marginBottom: 0, paddingBottom: 0, position: 'relative'}}
                  />
                  <span className="project-date">{project.startDate}</span>
                  <br />
                  <p className="project-title-settings mt-3">
                    {project.title}
                  </p>
                </div>
              </div>
            </span>
          </div>
        </>)
                })
                        

                }
         

            </div>
          </div>
        </div>
      </section>
    </>
    )
}