import React, { Component, useEffect, useState } from "react"

import emailjs from 'emailjs-com';

const ContactC = (props) => {
  const [name, setName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [email, setEmail] = useState(null)
  const [message, setMessage] = useState(null)
  const sendmail = () => {

    console.log(name, lastName, email, message, "data to name");
    if(!name&&!email&&!message){
       alert("fill information");
       return;
    }
    let data={
       name,lastName,message,email
    }
    emailjs.send("service_q400lsp","template_iq5mmpl",{
      from_name: name,
      to_name: "Abhinav",
      message: message,
      reply_to: email ,
      },"QZ9Bep0uvMaK3VlHT").then((result) => {
        console.log(result.text);
        alert("mail sent")
      }, (error) => {
        console.log(error.text);
      });
    // emailjs.sendForm('service_q400lsp', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID')
    // .then((result) => {
    //     window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
    // }, (error) => {
    //     console.log(error.text);
    // });

  }

  return (
    <>

      <section class="space-wapper pb-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="say-content">
                <span>Contact us</span>
                <h2>
                  Say <span class="diff">Hello</span>
                </h2>
                <br />
                <br />
                <br />
                <p>For any questions, assistance, or feedback</p>
                <p>
                  Email: <b>VKConstruct@gmail.com</b>{" "}
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="info-section">
                <form>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label for="">First Name*</label>
                        <input
                          type="text"
                          onChange={(e) => {
                            // e.preventDefault;
                            setName(e.target.value)
                          }}
                          class="form-control"
                          value={name}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="">Last Name*</label>
                        <input
                          type="text"
                          onChange={(e) => {
                            // e.preventDefault;
                            setLastName(e.target.value)
                          }}
                          value={lastName}
                          class="form-control"
                          id=""
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="">Email ID*</label>
                      <input
                        type="email"
                        onChange={(e) => {
                          // e.preventDefault;
                          setEmail(e.target.value)
                        }}
                        class="form-control"
                        id=""
                        value={email}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="">Message*</label>
                      <textarea
                        onChange={(e) => {
                          setMessage(e.target.value)
                        }}
                        value={message}
                        class="form-control"
                        name=""
                        id=""
                        cols="30"
                        placeholder=""
                        rows="10"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-12">
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        sendmail()
                      }}
                      class="blue-text-right"
                    >
                      Mail Us
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default ContactC
