import React, { Component, useState ,useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";
import './App.css';
import Home from './components/Home';
import {useNavigate} from 'react-router-dom'
import Blog from "./components/Blog";
import Contact from "./components/Contact";
import JoinUs from "./components/JoinUs";
import BlogPage from "./components/BlogPage";
import Services from "./components/Services";
import { Portfolio1 } from "./components/Portfolio";
// import "./cssfile/main.css"
// import "./cssfile/responsive.css";
// import "./cssfile/bootstrap.min.css"

function App() {
 
  // const history=useNavigate(); 

  let routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "connect", element: <Contact /> },
    { path: "JoinUs", element: <JoinUs /> },
    { path: "Blog", element: <Blog /> },
    { path: "blogpage/:id", element: <BlogPage /> },
    {path:"Services",element:<Services />},
    {path:'Portfolio',element:<Portfolio1/>}
    
  ]);
  return routes;

  // return (
  //   <>
    
  //           <Routes>
  //             <Route exact path="/" key="home" element={<Home />} />
  //             <Route exact path="/connect" key="connect" element={<Contact />} />
  //             <Route exact path="/JoinUs" key="JoinUs" element={<JoinUs />} />
  //              <Route exact path="/Blog" key="Blog" element={<Blog />}/>
  //              <Route exact path="/blogpage/:id" key="blogPage" element={<BlogPage/>}/>
  //             </Routes>
        
  //   </>
  // );
}

export default App;
