import React, { Component, useEffect, useState } from "react"
import Header from "../TopHeader"
import Abc from "../footer"
import homeActions from "../../actions/homeActions"
import emailjs from 'emailjs-com';
import ContactC from "../ContactC";
import { Portfolio } from "../portfo";
export const Portfolio1=()=>{
    return (<>
          <Header></Header>
          <section class="space-wapper">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-head work">
                <span>Portfolio</span>
                <h2>
                  Our <span class="diff">Work</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="top-wapper">
         
            <Portfolio/>
          
        </div>
      </section>
          <Abc></Abc>

    </>)
}