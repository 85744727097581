import React, { Component, useEffect, useState } from "react"
import Header from "../TopHeader"
import Abc from "../footer"
import homeActions from "../../actions/homeActions"
import emailjs from 'emailjs-com';
import ContactC from "../ContactC";

const Services = (props) => {
  const [name, setName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const sendmail = () => {

    console.log(name, lastName, email, message, "data to name");
    if(!name&&!email&&!message){
       alert("fill information");
       return;
    }
    let data={
       name,lastName,message,email
    }
    emailjs.send("service_q400lsp","template_iq5mmpl",{
      from_name: name,
      to_name: "Abhinav",
      message: message,
      reply_to: email ,
      }).then((result) => {
        console.log(result.text);
        alert("mail sent")
      }, (error) => {
        console.log(error.text);
      });
    // emailjs.sendForm('service_q400lsp', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID')
    // .then((result) => {
    //     window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
    // }, (error) => {
    //     console.log(error.text);
    // });

  }

  return (
    <>
      <Header></Header>
      <section class="space-wapper">
        <div class="container-fulid">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-head what-long">
                <span>what we do</span>
                <h2>
                  our <span class="diff">services</span>
                </h2>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div class="row">
            <div class="col-lg-12">
              <div class="p-content">
                <div class="banner-bg-video sceond-video">
                  <video autoPlay muted loop id="myVideo" preload="auto">
                    <source src="/img/sceond.mp4" type="video/mp4" />
                    Your browser does not support HTML5 video.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="p-content">
                <div class="by-title">
                  <h5>Empowering Construction Excellence, Together</h5>
                  <p>
                    Through collaborative efforts from our community of BIM
                    specialists, architects, engineers, project managers, and
                    other champions of construction innovation, we unite to
                    deliver solutions that advance the efficiency and impact of
                    BIM for our clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="space-wapper">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-4">
              <div class="list-item">
                <h2>Our Services</h2>
                <ul>
                  <li>
                    {" "}
                    <a href="#">MEPF BIM</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">Architectural BIM</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">Structural BIM</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">Scan to BIM</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">BIM Coordination</a>{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="list-item">
                <h2>Strategy</h2>
                <ul>
                  <li>
                    {" "}
                    <a href="#">Vision And Mission</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">Services Portfolio </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">technological Infrastructure</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#"> Thought Leadership </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#"> Talents Development </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">clients Engagement</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#"> Sustainability integration</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">Collaboration And integration</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">Quality Assurance</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">Continuos Improvement</a>{" "}
                  </li> 
                </ul>
              </div>
            </div>
            {/* <div class="col-lg-4 col-md-4">
              <div class="list-item">
                <h2>Strategy</h2>
                <ul>
                  <li>
                    {" "}
                    <a href="#">Accessibility & Safety </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#"> Training & Certifications </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#"> Workshops & Seminars </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#"> Sustainability Reporting </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#"> Environmental Services </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#"> Women’s Health & Empowerment </a>{" "}
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <Abc></Abc>
    </>
  )
}

export default Services
