import React, { Component, useEffect, useState } from "react";
import Header from "../TopHeader"
import Abc from "../footer"
import { Link, useLocation, useParams,useNavigate } from "react-router-dom";
import blogActions from "../../actions/homeActions";
import moment from "moment";
import NavBar from "../NavBar";
const BlogPage =()=> { 
   const params = useParams();
   const history=useNavigate(); 
   const [blogList,setBlog]=useState([]);
   const [currentPage,setCurrentPage]=useState(1);
   const [sizePerPage,setSizePerPage]=useState(6);
   const [pages,setPages]=useState(6);
   const [pageInfo,setpageInfo]=useState({});


   const blogPageInfo=()=>{
      let id =params.id;
      let payload ={
         _id:id
      };
      blogActions.getParticularBlog(payload,(err,res)=>{
         if(err){

         }else{
            setpageInfo(res.data);
         }
      })
   }
   useEffect(() => {
      blogPageInfo();
      getBlog();

   }, [])
   const getBlog = async () => {
      let dataToSend={
         limit: 3,
          page: 1,
      }
      blogActions.getBlog(dataToSend,(err,res)=>{
           if(err){
             console.log(err," appointments error")
           }else{
            setBlog(res.data.blogList);
             console.log(res.data," Apoointments are");
           }
         });
         
       };

    return (
     <>
      <button
         type="button"
         class="btn btn-floating btn-lg"
         id="btn-back-to-top">
      <img src="img/up-down.png" alt=""/>
      </button>
      <section class="" >
         <div class="banner-bg-video new-add-heigt">
            <img src="img/blog1.png" alt=""/>
         </div>
         <NavBar></NavBar>
      </section>
         <section class="space-wapper">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="section-head latest-Articles water-text">
                     <h2>Water, Water Everywhere And Not A Drop To Drink</h2>
                     <ul class="news-list">
                        <li><a href="#">NEWS</a></li>
                        <li><a href="#">1 March 2022</a></li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-1">
                  <div>
                     <ul class="icon-list">
                        <li><a href="#"><img src="img/indeed.png" alt=""/></a></li>
                        <li><a href="#"><img src="img/twitter.png" alt=""/></a></li>
                        <li><a href="#"><img src="img/fb.png" alt=""/></a></li>
                     </ul>
                  </div>
               </div>
               <div class="col-11">
                  <div class="water-content">
                     <p ><span>L</span> orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut<br class="relex"/> enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure </p>
                     <p>dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-6">
                  <div class="ad">
                     <img src="img/ad1.png" alt=""/>
                  </div>
               </div>
               <div class="col-lg-6">
                  <div class="ad">
                     <img src="img/ad2.png" alt=""/>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-12">
                  <div class="water-content ad-down">
                     <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                  </div>
               </div>
            </div>
         </div>
        
      </section>
      <section class="space-wapper down-space pt-0">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="Joining-text">
                     <h4>Related Articles</h4>
                  </div>
               </div>
            </div>
            <br/>
            <div class="row">
            {blogList.length>0&&blogList?blogList.map((ele)=>{
    let ArticleBody= ele.ArticleBody.substring(0, 100)
    return(
       <>
       
       <div class="col-lg-4 col-md-4">
          <div class="our-content">
             <img src={ele.imageUrl} alt=""/>
             <h3>{ele.ArticleTitle}</h3>
             <h5>{moment(ele.createAt).format("YYYY-MM-DD")} / <span>by {ele.publishedBy}</span></h5>
             <p>{ArticleBody}</p>
             <a href="#">Read More <img src="img/Arrow-next.png" alt=""/></a>
          </div>
       </div>
      
       </>
    )
 }):""}
 </div>
         </div>
      </section>
      
     
     <Abc></Abc>
     </>
    );
  
}

export default BlogPage;
